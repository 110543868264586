import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom'

import '@fontsource/roboto'

import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={extendTheme({
      fonts: {
        body: 'Roboto'
      }
    })}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
